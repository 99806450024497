import React from "react";

const Chooseus = () => {
  return (
    <>
      <section>
        <div className="why-choose-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <h2 className="section-title">Why Choose Us</h2>
                <p>
                  Choose us for expertly crafted furniture made from premium,
                  sustainable materials. We prioritize quality, durability, and
                  timeless design to enhance your living space.
                </p>

                <div className="row my-5">
                  <div className="col-6 col-md-6">
                    <div className="feature">
                      <div className="icon">
                        <div className="icon-img">
                          <img
                            src="assets\premium-quality.png"
                            alt="Image"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <h3>Premium Quality</h3>
                      <p>
                        We use only the finest materials, ensuring long-lasting
                        and beautiful furniture.
                      </p>
                    </div>
                  </div>

                  <div className="col-6 col-md-6">
                    <div className="feature">
                      <div className="icon">
                        <div className="icon-img">
                          <img
                            src="assets\handcraft.png"
                            alt="Image"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <h3>Expert Craftsmanship</h3>
                      <p>
                        Our skilled artisans create each piece with attention to
                        detail and precision
                      </p>
                    </div>
                  </div>

                  <div className="col-6 col-md-6">
                    <div className="feature">
                      <div className="icon">
                        <div className="icon-img">
                          <img
                            src="assets\customer.png"
                            alt="Image"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <h3>Custom Designs</h3>
                      <p>
                        Tailor-made furniture to match your personal style and
                        needs.
                      </p>
                    </div>
                  </div>

                  <div className="col-6 col-md-6">
                    <div className="feature">
                      <div className="icon">
                        <div className="icon-img">
                          <img
                            src="/assets/wood-carving.png"
                            alt="Image"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <h3>Exceptional Customer Service</h3>
                      <p>
                        We're dedicated to providing a seamless and satisfying
                        experience from selection to delivery
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="img-wrap">
                  <img
                    src="/assets/choose.jpeg"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chooseus;
