import React, { useState, useEffect } from "react";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";

const Reviews = () => {
  const testimonials = [
    {
      text: "I couldn’t be happier with my custom furniture from Royalteak. The quality is outstanding, and it fits perfectly in my living room! Highly recommend their services!",
      author: "Emily R",
      image: "assets/person_1.jpg",
    },
    {
      text: "From start to finish, the team was incredible! They listened to my ideas and crafted a beautiful dining table that my family adores. The craftsmanship is top-notch!",
      author: " Michael T.",
      image: "assets/person_2.jpg",
    },
    {
      text:"I was impressed with the level of detail and care that went into my custom order. It truly feels like a one-of-a-kind piece. Thank you for bringing my vision to life!",
      author: "Sarah L",
      image: "assets/person_3.jpg",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Navigate to the previous testimonial
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  // Navigate to the next testimonial
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Automatically change testimonials every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000); // Change every 5 seconds
    return () => clearInterval(interval);
  }, [currentIndex]);

  // Jump to a specific testimonial based on the circle clicked
  const handleCircleClick = (index: React.SetStateAction<number>) => {
    setCurrentIndex(index);
  };

  const { text, author, image } = testimonials[currentIndex];

  return (
    <section>
      <div className="testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto text-center">
              <h2 className="section-title">Testimonials</h2>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="testimonial-slider-wrap text-center">
                <div id="testimonial-nav">
                  <div className="prev" onClick={handlePrev}>
                    <i className="fi fi-rr-angle-small-left icons"></i>
                  </div>
                  <div className="next" onClick={handleNext}>
                    <i className="fi fi-rr-angle-small-right icons"></i>
                  </div>
                </div>

                <div className="testimonial-slider">
                  <div className="item">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 mx-auto">
                        <div className="testimonial-block text-center">
                          <p className="mb-5">
                            <p>&ldquo;{text}&rdquo;</p>
                          </p>

                          <div className="author-info">
                            <div className="author-pic">
                              <img
                                src={image}
                                alt={author}
                                className="img-fluid"
                              />
                            </div>
                            <h3 className="font-weight-bold">{author}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Circle Indicators */}
                <div className="circle-indicators">
                  {testimonials.map((_, index) => (
                    <div
                      key={index}
                      className={`circle ${
                        index === currentIndex ? "active" : ""
                      }`}
                      onClick={() => handleCircleClick(index)}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CSS Styles */}
      <style>
        {`
          .testimonial-section {
            padding: 50px 0;
          }
          .testimonial-block {
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 10px;
            background-color: #f9f9f9;
          }
          .testimonial-slider-wrap {
            position: relative;
          }
          .prev,
          .next {
            cursor: pointer;
            font-size: 24px;
            margin: 10px;
          }
          .circle-indicators {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .circle {
            width: 10px;
            height: 10px;
            background-color: #ddd;
            border-radius: 50%;
            margin: 0 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
          .circle.active {
            background-color: #000; /* Active circle color */
          }
        `}
      </style>
    </section>
  );
};

export default Reviews;
