import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Material = () => {
  // State to control the current image index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Array of product images and product names
  const productImages = [
    {
      imgSrc: "/assets/product-1.png",
      product: "Hand Carved Table",
    },
    {
      imgSrc: "/assets/product-2.png",
      product: "Elegant Sofa",
    },
    {
      imgSrc: "/assets/product-3.png",
      product: "Classic Chair",
    },
  ];

  // Function to handle sliding to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === productImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Automatically slide to the next image every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(nextImage, 3000); // Change image every 3 seconds

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to handle clicking on the circular indicators
  const handleIndicatorClick = (index:any) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <section className="bg-color">
        <div className="product-section">
          <div className="container">
            <div className="row">
              {/* Start Column 1 */}
              <div className="col-md-12 col-lg-3 mb-5 mb-lg-0">
                <h2 className="mb-4 section-title">
                  Crafted with excellent material.
                </h2>
                <p className="mb-4">
                  Our furniture is crafted with the finest materials, ensuring
                  both durability and elegance. Each piece is designed to last,
                  combining timeless beauty with exceptional craftsmanship.
                </p>
                <p>
                  <a href="#" className="btn">
                    Show More
                  </a>
                </p>
              </div>
              {/* End Column 1 */}

              {/* Mobile Slider - Only visible on mobile */}
              <div className="col-12 d-md-none mb-2">
                <div className="slider-container">
                  <div className="product-item">
                    {/* Corrected to use imgSrc */}
                    <img
                      src={productImages[currentIndex].imgSrc}
                      alt={`Product ${currentIndex + 1}`}
                      className="img-fluid product-thumbnail slide"
                    />
                    <p className="product-name">
                      {productImages[currentIndex].product}
                    </p>
                  </div>
                </div>
                {/* Circular indicators for each image */}
                <div className="indicator-container">
                  {productImages.map((_, index) => (
                    <div
                      key={index}
                      className={`indicator ${
                        currentIndex === index ? "active" : ""
                      }`}
                      onClick={() => handleIndicatorClick(index)}
                    />
                  ))}
                </div>
              </div>
              {/* End Mobile Slider */}

              {/* Static Images for larger screens - Visible on tablet (md) and larger screens */}
              <div className="col-lg-9 d-none d-md-block">
                <div className="row">
                  {productImages.map((image, index) => (
                    <div
                      className="col-12 col-md-4 col-lg-4 mb-5 mb-md-0 mb-md-3"
                      key={index}
                    >
                      <div className="product-item">
                        <img
                          src={image.imgSrc}
                          alt={`Static Product ${index + 1}`}
                          className="img-fluid product-thumbnail"
                        />
                      </div>
                      <div className="product-divs pt-3">
                        <p className="mb-0">{image.product}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* End Static Images */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Material;
