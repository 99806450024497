import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import axios from "axios";
import { useLocation } from "react-router";
import { Button, Input } from "antd";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "250px",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const Contactus = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openview, setOpenview] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({
    name: "",
    phone: "",
    mail: "",
    productid: "",
    message: "",
  });

  const location = useLocation();

  // Extract the productId from the URL parameters
  const query = new URLSearchParams(location.search);
  const productId = query.get("productId");

  // Autofill the productid field with the value from URL params
  useEffect(() => {
    if (productId) {
      setData((prevData) => ({
        ...prevData,
        productid: productId,
      }));
    }
  }, [productId]);

  const handleBooking = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate all fields
    if (
      !data.name ||
      !data.phone ||
      !data.mail ||
      !data.productid ||
      !data.message
    ) {
      alert("Please fill out all fields");
      return;
    }

    setLoading(true);
    try {
      // Make POST request to the API
      await axios.post("https://royalteakapi.onrender.com/ContactUs", {
        name: data.name,
        userPhonenumber: data.phone,
        userEmail: data.mail,
        productid: data.productid,
        message: data.message,
      });

      // Reset form data after successful submission
      setData({
        name: "",
        phone: "",
        mail: "",
        productid: "",
        message: "",
      });

      setOpen(true); // Show success message
      handleOpen();
    } catch (err) {
      console.error("Submission error:", err);
      alert("Failed to submit. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Banner />
      <section className="pt-5 bg-color">
        <div className="container">
          <div className="block">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-8 pb-4">
                {/* Contact Information */}
                <div className="row mb-5 c-rows">
                  {/* Phone Section */}
                  <div className="col-lg-4">
                    <div className="service no-shadow align-items-center link horizontal d-flex active">
                      <div className="service-icon color-1">
                        {/* Phone Icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-telephone-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                          />
                        </svg>
                      </div>
                      <div className="service-contents">
                        <p className="txt-forms">+91 7094913208</p>
                      </div>
                    </div>
                  </div>

                  {/* Email Section */}
                  <div className="col-lg-4">
                    <div className="service no-shadow align-items-center link horizontal d-flex active">
                      <div className="service-icon color-1">
                        {/* Email Icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-envelope-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                        </svg>
                      </div>
                      <div className="service-contents">
                        <p className="txt-forms">royalteakchennai@gmail.com</p>
                      </div>
                    </div>
                  </div>

                  {/* Contact Information Sections */}
                  <div className="col-lg-4">
                    <div className="service no-shadow link horizontal d-flex active">
                      <div className="service-icon color-1">
                        {/* Location Icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-geo-alt-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                      </div>
                      <div className="service-contents">
                        <p>
                          No.48,East mada church st, Royapuram, Chennai - 600
                          013
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Contact Form */}
                <form onSubmit={handleBooking}>
                  <div className="form-group">
                    <label className="text-black" htmlFor="name">
                      Name
                    </label>
                    <Input
                      className="form-control"
                      value={data.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label className="text-black" htmlFor="mail">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="example@gmail.com"
                      value={data.mail}
                      onChange={(e) =>
                        setData({ ...data, mail: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label className="text-black" htmlFor="phone">
                      Phone No
                    </label>
                    <Input
                      className="form-control"
                      value={data.phone}
                      onChange={(e) =>
                        setData({ ...data, phone: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label className="text-black" htmlFor="productid">
                      Product Id
                    </label>
                    <Input
                      className="form-control"
                      placeholder="Eg:TA0001"
                      value={data.productid}
                    />
                  </div>

                  <div className="form-group">
                    <label className="text-black" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      cols={30}
                      rows={5}
                      value={data.message}
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                    />
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>

                <div>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div className="modal-img">
                        <img
                          src="/assets/images/sent-mail.gif"
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <p className="txt-c">Your message has been send successfully</p>
                      </Typography>

                      <div className="d-flex justify-content-center pt-3">
                        <Button className="btn-m">
                          <Link to="/">OK</Link>
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
