import React, { useEffect } from "react";

const Banner = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <>
      <section>
        <div className="bg-2">
          <img src="/assets/Banner-2.png" className="img-fluid" alt="img" />
        </div>
      </section>

      <section>
        <div className="maps">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2959.941464192225!2d80.29255377359162!3d13.107302511891575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526f6a41d7e9a3%3A0xe65ed4e4511478db!2s48%2C%20S%20Madha%20Church%20St%2C%20Pudumanaikuppam%2C%20Royapuram%2C%20Chennai%2C%20Tamil%20Nadu%20600013!5e1!3m2!1sen!2sin!4v1729496108466!5m2!1sen!2sin"
            style={{ border: 0 }}
            className="map-frames"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Banner;
