import React from "react";

const Fixedimage = () => {
  
  return (
    <>
      <section className="bg-img">

      </section>
    </>
  );
};

export default Fixedimage;
