import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div>
        <footer className="footer-section">
          <div className="container relative">
            <div className="row pb-3">
              <div className="col-lg-5">
                <div className="mb-4 footer-logo-wrap">
                  <a href="/" className="footer-logo">
                    <div className="logo-f">
                      <img
                       src="/assets/images/logo (2).png"
                        className="img-fluid"
                        alt="logo"
                      />
                    </div>
                  </a>
                </div>
                <p className="mb-4" style={{ color: "#394c6c" }}>
                  We are specialize in crafting high-quality wooden furniture
                  that combines beauty and durability. Our skilled artisans
                  create both ready-made and custom designs tailored to your
                  unique style. Committed to sustainability, we ensure that
                  every piece reflects exceptional craftsmanship and timeless
                  appeal.
                </p>

                <div className="custom-social">
                  <div>
                    <a href="https://www.facebook.com/profile.php?id=61567468620352">
                      <i className="fi fi-brands-facebook social"></i>
                    </a>
                  </div>
                  <div>
                    <a href="https://www.instagram.com/royalteakfurniture/">
                      <i className="fi fi-brands-instagram social"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-3 footer-g">
                <ul className="list-unstyled ">
                  <li
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/products/:category">Product Categories</Link>
                  </li> */}
                  <li
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/aboutus">About us</Link>
                  </li>
                  <li
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/contactus">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-3 footer-g">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex gap-2">
                    <div>
                      <i className="fi fi-rr-call-outgoing f-icon"></i>
                    </div>
                    <div className="loc-txt">7094913208</div>
                  </div>
                  <div className="d-flex gap-2">
                    <div>
                      <i className="fi fi-rr-envelope f-icon"></i>
                    </div>
                    <div className="loc-txt">royalteakchennai@gmail.com</div>
                  </div>

                  <div className="d-flex gap-2">
                    <div>
                      <i className="fi fi-rr-marker f-icon"></i>
                    </div>
                    <div className="loc-txt">
                      No.48,East mada church st, Royapuram, Chennai - 600 013
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-top copyright">
              <p
                className="text-center text-lg-start mb-0"
                style={{ color: "#394c6c" }}
              >
                 &copy; Copyright 2024
                royalteak All Rights Reserved
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
