import React, { useEffect } from "react";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <section>
        <div className="bg-2">
          <img src="/assets/bg-about.png" className="img-fluid" alt="img" />
        </div>
      </section>
      <section className="top-g pb-lg-4">
        <div className="container">
          <div className="pt-3">
            <h2 className="txt-1">About Us</h2>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    Royal teak Furniture – The Best Furniture Shop in Chennai
                    for All Your Wooden Furniture Needs Every home and office
                    needs quality furniture. No matter where you go, furniture
                    plays an essential role in enhancing functionality and
                    aesthetics. At Royalteek, we understand the value of
                    well-crafted furniture that combines style, durability, and
                    comfort. Whether you prefer timeless traditional designs or
                    sleek modern styles, our vast collection of teakwood
                    furniture offers something for everyone.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-1.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-3 row-change">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-2.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    As a direct factory outlet with over{" "}
                    <span>15 years of experience</span>, Royalteak has
                    established itself as one of the leading furniture shops in
                    Chennai, specializing in premium Indonesian teakwood. Our
                    commitment is to provide beautifully crafted pieces that not
                    only elevate your home or office interiors but also improve
                    the quality of your life. We believe that furniture should
                    not only be aesthetically pleasing but also durable enough
                    to last for years.
                  </p>
                </div>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    At Royalteak, we focus on the best materials, and{" "}
                    <span>Indonesian teakwood</span> stands out for its
                    exceptional strength and natural beauty. Teakwood has been
                    prized for centuries due to its resistance to weathering,
                    pests, and wear. It can last for decades, even centuries,
                    with proper care, making it the ideal choice for both indoor
                    and outdoor furniture. This timeless quality of teakwood
                    makes it a popular and trusted material for discerning
                    customers seeking long-lasting home furnishing solutions
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-3.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-3  row-change">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-5.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    We offer a wide variety of teakwood furniture for different
                    uses, such as dining tables and chairs, living room
                    furniture, bedroom sets, puja room doors, main entrance
                    doors, and more. Each piece is{" "}
                    <span>crafted with precision</span> and designed to enhance
                    both the functionality and <span>aesthetic appeal</span> of
                    your space. In addition to our standard collections,
                    Royalteek also provides customized furniture to meet your
                    specific requirements, allowing you to tailor every detail
                    to suit your personal style and preferences.
                  </p>
                </div>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    Beyond home furniture, Royalteak imports durable and stylish
                    office and outdoor furniture, offering the best of quality
                    at affordable prices. We take pride in being one of the few
                    furniture showrooms in Chennai to provide such{" "}
                    <span> competitive prices</span> without compromising on the
                    quality of our products. Our goal is to ensure that our
                    customers receive unmatched value in both design and
                    durability.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-6.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-3  row-change">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-7.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    At Royalteak, our <span>skilled artisans</span> and
                    craftsmen pay meticulous attention to every detail, ensuring
                    each piece of furniture is unique and built to last. Our
                    passion for excellence is reflected in our craftsmanship,
                    and our dedication to customer satisfaction is evident in
                    the lasting relationships we build with our clients. We also
                    offer exceptional after-sales service, ensuring that your
                    experience with us continues long after your purchase.
                  </p>
                </div>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    Our team of <span>professional carpenters</span> and
                    interior designers are committed to designing furniture that
                    suits both traditional and modern interiors, helping you
                    achieve the perfect look within your budget. Whether you’re
                    looking for classic wooden pieces or contemporary designs,
                    Royalteak promises to deliver the best furniture to
                    complement your space.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-1.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-3 pb-3 row-change">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <div className="img-about">
                    <img
                      src="/assets/a-img-10.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="about-rows">
                  <p className="txt-p">
                    This version emphasizes Royalteak’s core strengths, such as
                    their <span>long-standing expertise</span>, direct factory
                    outlet, Indonesian teakwood, customization options, and
                    commitment to quality and customer service. Let me know if
                    you'd like further modifications!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
