import React, { useEffect } from "react";
import Material from "./material";
import Chooseus from "./Chooseus";
import Interiordesign from "./Interiordesign";
import Reviews from "./Reviews";
import Banner from "../Contact/Banner";
import Fixed from "./Fixed";
import Achive from "./Achivements";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <section>
        <div className="bg-2">
          <img src="/assets/Banner-1.png" className="img-fluid" alt="img" />
        </div>
      </section>
      <Material />
      <Chooseus />
      <Fixed />
      <Interiordesign />
      <Achive />
      <Reviews />
    </>
  );
};

export default Home;
