import React from "react";

const Interiordesign = () => {
  return (
    <>
      <section>
        <div className="we-help-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-7 mb-5 mb-lg-0">
                <div className="imgs-grid">
                  <div className="grid grid-1">
                    <img src="/assets/in-1.jpeg" className="img-fluid" alt="Untree.co" />
                  </div>
                  <div className="grid grid-2">
                    <img src="/assets/in-3.jpeg" alt="Untree.co" />
                  </div>
                  <div className="grid grid-3">
                    <img src="/assets/in-2.jpeg" alt="Untree.co" />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 ps-lg-5">
                <h2 className="section-title mb-4">
                  Crafting Your Dreams: Personalized Furniture Designs
                </h2>
                <p>
                  We specialize in crafting custom furniture designs to match
                  your individual style and needs. Each piece is carefully
                  designed and handcrafted to fit your space perfectly. Let us
                  bring your vision to life with exceptional craftsmanship.
                </p>

                <ul className="list-unstyled custom-list my-4">
                  <li>
                    Each piece is uniquely designed to reflect your personal
                    taste and enhance your home’s aesthetics.
                  </li>
                  <li>
                    Our custom furniture is crafted to fit seamlessly into your
                    space, maximizing both functionality and design
                  </li>
                  <li>
                    We combine skilled artisanship premium materials to
                    ensure durability and timeless appeal in every piece.
                  </li>
                  <li>
                    We work closely with you throughout the design process to
                    bring your vision to life, ensuring complete.
                  </li>
                </ul>
                <p>
                  <a href="#" className="btn">
                    Explore
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Interiordesign;
