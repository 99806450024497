import React, { useState, useEffect, useRef } from "react";
import { Button, Card } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

// Define the Deal interface
interface Deal {
  id: number;
  imageUrl: string;
  product: string;
}

const Products: React.FC = () => {
  const { category } = useParams<{ category: string }>();

  const cardData = [
    {
      imgSrc: "/assets/images/chair-1.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0001",
    },
    {
      imgSrc: "/assets/images/chair-2.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0002",
    },
    {
      imgSrc: "/assets/images/chair-3.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0003",
    },
    {
      imgSrc: "/assets/images/chair-4.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0004",
    },
    {
      imgSrc: "/assets/images/chair-5.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0005",
    },
    {
      imgSrc: "/assets/images/chair-6.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0006",
    },
    {
      imgSrc: "/assets/images/chair-8.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0007",
    },
    {
      imgSrc: "/assets/images/chair-9.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0008",
    },
    {
      imgSrc: "/assets/images/chair-11.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH009",
    },
    {
      imgSrc: "/assets/images/chair-12.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0010",
    },
    {
      imgSrc: "/assets/images/chair-13.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0011",
    },
    {
      imgSrc: "/assets/images/chair-14.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0012",
    },
    {
      imgSrc: "/assets/images/chair-15.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0013",
    },
    {
      imgSrc: "/assets/images/chair-16.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0014",
    },
    {
      imgSrc: "/assets/images/chair-17.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0015",
    },
    {
      imgSrc: "/assets/images/chair-18.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0016",
    },
    {
      imgSrc: "/assets/images/Chair-20.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0017",
    },
    {
      imgSrc: "/assets/images/Chair-21.jpeg",
      offer: "15% off",
      Product: "Chair",
      id: "CH0018",
    },
    {
      imgSrc: "/assets/images/buffet-1.jpeg",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0001",
    },
    {
      imgSrc: "/assets/images/buffet-5.jpeg",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0002",
    },
    {
      imgSrc: "/assets/images/buffet-6.jpeg",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0003",
    },
    {
      imgSrc: "/assets/images/cots-1.jpeg",
      offer: "15% off",
      Product: "Cots",
      id: "CT0001",
    },
    {
      imgSrc: "/assets/images/cots-2.jpeg",
      offer: "15% off",
      Product: "Cots",
      id: "CT0002",
    },
    {
      imgSrc: "/assets/images/cots-3.jpeg",
      offer: "15% off",
      Product: "Cots",
      id: "CT0003",
    },
    {
      imgSrc: "/assets/images/cots-4.jpeg",
      offer: "15% off",
      Product: "Cots",
      id: "CT0004",
    },
    {
      imgSrc: "/assets/images/cots-5.jpeg",
      offer: "15% off",
      Product: "Cots",
      id: "CT0005",
    },
    {
      imgSrc: "/assets/images/Cots-6.jpeg",
      offer: "15% off",
      Product: "Cots",
      id: "CT0006",
    },
    {
      imgSrc: "/assets/images/Cots-8.jpeg",
      offer: "15% off",
      Product: "Cots",
      id: "CT0007",
    },
    {
      imgSrc: "/assets/images/dressing table-3.jpeg",
      offer: "15% off",
      Product: "Showcase",
      id: "SC0001",
    },
    {
      imgSrc: "/assets/images/dressing table-4.jpeg",
      offer: "15% off",
      Product: "Showcase",
      id: "SC0002",
    },
    {
      imgSrc: "/assets/images/dressing table-5.jpeg",
      offer: "15% off",
      Product: "Showcase",
      id: "SC0003",
    },
    {
      imgSrc: "/assets/images/dressing table-6.jpeg",
      offer: "15% off",
      Product: "Showcase",
      id: "SC0004",
    },
    {
      imgSrc: "/assets/images/dressing table-8.jpeg",
      offer: "15% off",
      Product: "Showcase",
      id: "SC0005",
    },
    {
      imgSrc: "/assets/images/Showcase-1.jpeg",
      offer: "15% off",
      Product: "Showcase",
      id: "SC0006",
    },
    {
      imgSrc: "/assets/images/Showcase-2.jpeg",
      offer: "15% off",
      Product: "Showcase",
      id: "SC0007",
    },
    {
      imgSrc: "/assets/images/mirror frmaes-1.jpeg",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0001",
    },
    {
      imgSrc: "/assets/images/mirror frmaes-2.jpeg",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0002",
    },
    {
      imgSrc: "/assets/images/mirror frmaes-5.jpeg",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0003",
    },
    {
      imgSrc: "/assets/images/sofa-1.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0001",
    },
    {
      imgSrc: "/assets/images/sofa-2.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0002",
    },
    {
      imgSrc: "/assets/images/sofa-3.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0003",
    },
    {
      imgSrc: "/assets/images/sofa-4.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0004",
    },
    {
      imgSrc: "/assets/images/sofa-5.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0005",
    },
    {
      imgSrc: "/assets/images/sofa-6.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0006",
    },
    {
      imgSrc: "/assets/images/sofa-7.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0007",
    },
    {
      imgSrc: "/assets/images/sofa-8.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0008",
    },
    {
      imgSrc: "/assets/images/sofa-9.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0009",
    },
    {
      imgSrc: "/assets/images/sofa-10.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0010",
    },
    {
      imgSrc: "/assets/images/Sofa-11.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0011",
    },
    {
      imgSrc: "/assets/images/Sofa-12.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0012",
    },
    {
      imgSrc: "/assets/images/Sofa-13.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0013",
    },
    {
      imgSrc: "/assets/images/Sofa-14.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0014",
    },
    {
      imgSrc: "/assets/images/Sofa-15.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0015",
    },
    {
      imgSrc: "/assets/images/Sofa-16.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0016",
    },
    {
      imgSrc: "/assets/images/Sofa-17.jpeg",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0017",
    },
    {
      imgSrc: "/assets/images/tinning-1.jpeg",
      offer: "15% off",
      Product: "Dinning Table",
      id: "DT0001",
    },
    {
      imgSrc: "/assets/images/tinning-2.jpeg",
      offer: "15% off",
      Product: "Dinning Table",
      id: "DT0002",
    },
    {
      imgSrc: "/assets/images/tinning-3.jpeg",
      offer: "15% off",
      Product: "Dinning Table",
      id: "DT0003",
    },
    {
      imgSrc: "/assets/images/tinning-4.jpeg",
      offer: "15% off",
      Product: "Dinning Table",
      id: "DT0004",
    },
    {
      imgSrc: "/assets/images/tinning-5.jpeg",
      offer: "15% off",
      Product: "Dinning Table",
      id: "DT0005",
    },
    {
      imgSrc: "/assets/images/tinning-6.jpeg",
      offer: "15% off",
      Product: "Dinning Table",
      id: "DT0006",
    },
    {
      imgSrc: "/assets/images/tinning-7.jpeg",
      offer: "15% off",
      Product: "Dinning Table",
      id: "DT0007",
    },
    {
      imgSrc: "/assets/images/TV-1.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0001",
    },
    {
      imgSrc: "/assets/images/TV-2.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0002",
    },
    {
      imgSrc: "/assets/images/TV-3.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0003",
    },
    {
      imgSrc: "/assets/images/TV-4.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0004",
    },
    {
      imgSrc: "/assets/images/TV-5.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0005",
    },
    {
      imgSrc: "/assets/images/TV-7.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0007",
    },
    {
      imgSrc: "/assets/images/TV-8.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0008",
    },
    {
      imgSrc: "/assets/images/TV-9.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0009",
    },
    {
      imgSrc: "/assets/images/TV-10.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0010",
    },
    {
      imgSrc: "/assets/images/TV-11.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0011",
    },
    {
      imgSrc: "/assets/images/TV-12.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0012",
    },
    {
      imgSrc: "/assets/images/TV-13.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0013",
    },
    {
      imgSrc: "/assets/images/TV-14.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0014",
    },
    {
      imgSrc: "/assets/images/TV-16.jpeg",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0015",
    },
    {
      imgSrc: "/assets/images/Carved wood-1.jpeg",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0001",
    },
    {
      imgSrc: "/assets/images/Swing-1.jpeg",
      offer: "15% off",
      Product: "Swing",
      id: "SW0001",
    },
    {
      imgSrc: "/assets/images/Table-1.jpeg",
      offer: "15% off",
      Product: "Table",
      id: "TA0001",
    },
    {
      imgSrc: "/assets/images/Table-2.jpeg",
      offer: "15% off",
      Product: "Table",
      id: "TA0002",
    },
    {
      imgSrc: "/assets/images/Table-3.jpeg",
      offer: "15% off",
      Product: "Table",
      id: "TA0003",
    },
    {
      imgSrc: "/assets/images/Table-4.jpeg",
      offer: "15% off",
      Product: "Table",
      id: "TA0004",
    },
    {
      imgSrc: "/assets/images/chair-7.jpeg",
      offer: "15% off",
      Product: "Table",
      id: "TA0005",
    },
    {
      imgSrc: "/assets/images/chair-10.jpeg",
      offer: "15% off",
      Product: "Table",
      id: "TA0006",
    },
    {
      imgSrc: "/assets/images/Table-6.jpeg",
      offer: "15% off",
      Product: "Table",
      id: "TA0007",
    },
    {
      imgSrc: "/assets/images/dressing table-1.jpeg",
      offer: "15% off",
      Product: "Book Shelves",
      id: "BS0001",
    },
    {
      imgSrc: "/assets/images/dressing table-7.jpeg",
      offer: "15% off",
      Product: "Book Shelves",
      id: "BS0002",
    },
    {
      imgSrc: "/assets/images/dressing table-2.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0001",
    },
    {
      imgSrc: "/assets/images/buffet-2.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0002",
    },
    {
      imgSrc: "/assets/images/buffet-3.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0003",
    },
    {
      imgSrc: "/assets/images/buffet-4.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0004",
    },
    {
      imgSrc: "/assets/images/mirror frmaes-3.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0005",
    },
    {
      imgSrc: "/assets/images/Chest-1.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0006",
    },
    {
      imgSrc: "/assets/images/Chest-2.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0007",
    },
    {
      imgSrc: "/assets/images/Chest-3.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0008",
    },
    {
      imgSrc: "/assets/images/Ct-4.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0009",
    },
    {
      imgSrc: "/assets/images/Ct-5.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0010",
    },
    {
      imgSrc: "/assets/images/Ct-6.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0011",
    },
    {
      imgSrc: "/assets/images/Ct-7.jpeg",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0012",
    },
    {
      imgSrc: "/assets/images/Mirror-1.jpeg",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0001",
    },
    {
      imgSrc: "/assets/images/Mirror-2.jpeg",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0002",
    },
  ];

  // Sample data with multiple images
  const deals: Deal[] = [
    {
      id: 1,
      imageUrl: "",
      product: "Cots",
    },
    {
      id: 2,
      imageUrl: "",
      product: "Sofa",
    },
    {
      id: 3,
      imageUrl: "",
      product: "Chair",
    },
    {
      id: 4,
      imageUrl: "",
      product: "Table",
    },
    {
      id: 5,
      imageUrl: "",
      product: "Book Shelves",
    },
    {
      id: 6,
      imageUrl: "",
      product: "Dressing Table",
    },
  ];

  const filteredCardData = category
    ? cardData.filter(
        (card) => card.Product.toLowerCase() === category.toLowerCase()
      )
    : cardData;

  // State for current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [filteredDeals, setFilteredDeals] = useState<Deal[]>(deals);
  const imageRef = useRef<HTMLDivElement>(null); 
  const navigate = useNavigate();



  const showFullscreen = (index: number) => {
    setCurrentImageIndex(index);
    setIsFullscreen(true);
    if (imageRef.current) {
      // Request fullscreen for the image container
      if (imageRef.current.requestFullscreen) {
        imageRef.current.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleCloseFullscreen = () => {
    setIsFullscreen(false);
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < filteredCardData.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : filteredCardData.length - 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isFullscreen) {
        if (event.key === "ArrowRight") {
          handleNext();
        } else if (event.key === "ArrowLeft") {
          handlePrev();
        } else if (event.key === "Escape") {
          handleCloseFullscreen(); 
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullscreen]);

  const handleEnquiryClick = (productId: string) => {
    // Navigate to the Contact Us page with the product ID
    navigate(`/contactus?productId=${productId}`);
    // navigate(`/contactus/${productId}`);
  };

  return (
    <section className="pt-3 pb-4 gaps">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center mt-4 p-0">
          <h2 className="product-txt text-start w-100">{category}</h2>
        </div>

        <div className="row gx-4 gy-4 products-row">
          {filteredCardData.length > 0 ? (
            filteredCardData.map((card, index) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="card-gap">
                  <Card
                    className="card-img"
                    cover={
                      <div className="offer-img-product">
                        <div className="d-flex justify-content-center">
                          <div className="card-img-div">
                            <img
                              src={card.imgSrc}
                              alt={card.Product}
                              className="img-fluid"
                              onClick={() => showFullscreen(index)}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  >
                    {/* Card body content */}
                    <div className="p-3">
                      <p>
                        {card.Product} | ID: {card.id}
                      </p>
                      {/* Button for Enquiry */}
                      <Button
                        className="btn-change"
                        type="link"
                        onClick={() => handleEnquiryClick(card.id)}
                      >
                        Enquiry
                      </Button>
                    </div>
                  </Card>
                </div>
              </div>
            ))
          ) : (
            <p>No products available for the selected category.</p>
          )}
        </div>

        {isFullscreen && (
          <div ref={imageRef} className="fullscreen-image-viewer">
            {/* Left Arrow Button */}
            <Button onClick={handlePrev} className="arr-btns-1">
              <i className="fi fi-rr-angle-small-left"></i>
            </Button>

            {/* Image Center */}
            <div className="img-center">
              <img
                src={filteredCardData[currentImageIndex]?.imgSrc}
                className="img-fluid"
              />
            </div>

            {/* Right Arrow Button */}
            <Button onClick={handleNext} className="arr-btns-2">
              <i className="fi fi-rr-angle-small-right"></i>
            </Button>

            {/* Close Button */}
            <Button onClick={handleCloseFullscreen} className="xx">
              <i className="fi fi-rr-cross-small"></i>
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Products;
